import React from 'react';
import './footer.scss';
import FormFooter from '../../../components/LesPixionautes/FormFooter/FormFooter';

// Importation des images
import TwitterLogo from '../../../assets/logo/TWITTER.png';
import TwitchLogo from '../../../assets/logo/TWITCH.png';
import DiscordLogo from '../../../assets/logo/DISCORD.png';
import InstagramLogo from '../../../assets/logo/INSTAGRAM.png';
import TiktokLogo from '../../../assets/logo/TIKTOK.png';
import YoutubeLogo from '../../../assets/logo/YOUTUBE.png';

function Footer() {
  return (
    <footer className='footer'>
      <div className='footer__left'>
        <FormFooter />
        <p className='footer__text'>2024 - LESPIXIONAUTES.FR Tous droits réservés</p>
      </div>
      <div className="footer__right">
        <p className='text_footer'>
          <strong>Check l'intro du live ! C'est fun & Rétro !</strong> <br />
          Graphiste Professionnel & Créateur de contenu <br />
          FPS • Horror • Enigme • Aventure • Fun • Commu & Coop • Art
        </p>  

        <div className='container__logo'>
          <a href="https://www.twitter.com/@pixetmotion" aria-label="Twitter Pix&Motion">
            <img src={TwitterLogo} alt="Logo Twitter Pix&Motion" />
          </a> 
          <a href="https://twitch.tv/pixetmotion" aria-label="Twitch Pix&Motion">
            <img src={TwitchLogo} alt="Logo Twitch Pix&Motion" />
          </a>
          <a href="https://discord.gg/htVnjEqqtE" aria-label="Discord Pix&Motion">
            <img src={DiscordLogo} alt="Logo Discord Pix&Motion" />
          </a>
          <a href="https://www.instagram.com/twitchpixetmotion/" aria-label="Instagram Pix&Motion">
            <img src={InstagramLogo} alt="Logo Instagram Pix&Motion" />
          </a>
          <a href="https://www.tiktok.com/@pixetmotion" aria-label="Tiktok Pix&Motion">
            <img src={TiktokLogo} alt="Logo Tiktok Pix&Motion" />
          </a>
          <a href="https://www.youtube.com/@pixetmotiongaming" aria-label="YouTube Pix&Motion">
            <img src={YoutubeLogo} alt="Logo YouTube Pix&Motion" />
          </a>
        </div> 

        <div className="link">
          <a href="#accueil">ACCUEIL • </a>  
          <a href="#seasonpass">SEASON PASS • </a>  
          <a href="#cardgame">CARD GAME • </a>  
          <a href="#studio-graphique">STUDIO GRAPHIQUE • </a>  
          <a href="#staff">STAFF • </a>  
          <a href="#partner">PARTENAIRE</a>  
          {/* <a href="#boutique">BOUTIQUE • </a>
          <a href="#cgv">CGV</a>     */}
        </div>   
      </div>
    </footer>
  );
}

export default Footer;
