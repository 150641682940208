import React, { useState } from 'react';
import './Partner.scss';

// Importez vos images statiques ici
import GoCléCd from '../../../assets/partner/goclecd.webp';
import FullLife from '../../../assets/partner/FULLLIFE.webp';
import HorusX from '../../../assets/partner/HORUSX.webp';
import MaVision from '../../../assets/partner/MA-VISION (1).webp';

function Partner() {
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <section id='partner' className='section-partner'>
      <img
        src={require('../../../assets/titles/partenaires-2023.webp')}
        alt="Section Partenaire"
        className='titlepartner'
      />
      <div className="container-partner">
        {[{
          src: GoCléCd,
          alt: "Logo GoCléCd"
        }, {
          src: FullLife,
          alt: "Logo FullLife"
        }, {
          src: HorusX,
          alt: "Logo HorusX"
        }, {
          src: MaVision,
          alt: "Logo MaVision"
        }].map((partner, index) => (
          <img
            key={index}
            src={partner.src}
            alt={partner.alt}
            onClick={() => openModal(partner.src)}
            className="partner-thumbnail"
          />
        ))}
      </div>

      {selectedImage && (
        <div className='modalpartner' onClick={closeModal}>
          <div className='modalpartner-content' onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Modal Partner" />
            <button className="close-modal" onClick={closeModal}>&times;</button>
          </div>
        </div>
      )}
    </section>
  );
}

export default Partner;
