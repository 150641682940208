import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.scss';

// Importez les images à partir du fichier d'index
import * as images from '../../../assets/card-game';

const Carousel = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const settings = {
    dots: true,
    speed: 1000,
    slidesToShow: 8,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      { breakpoint: 770, settings: { slidesToShow: 3 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } }
    ]
  };

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const imageKeys = Object.keys(images);

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {imageKeys.map(key => (
          <div key={key} className="slider-item" onClick={() => openModal(images[key])}>
            <img src={images[key]} alt={`Carte ${key}`} />
          </div>
        ))}
      </Slider>

      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content">
            <img src={selectedImage} alt="Selected" />
            <button className="close-modal" onClick={closeModal}>&times;</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Carousel;
