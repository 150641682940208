import React, { useState } from 'react';
import './StudioGraphique.scss';

// Importez vos médias statiques ici
import EmoteAnim from '../../../assets/studio-graphique/Emotes_Anim_DarkRach-6.webm';
import EmoteNaked from '../../../assets/studio-graphique/Emotes_Nacked_1 (1).webp';
import TransitionAnim from '../../../assets/studio-graphique/Sama_Transition_anim.webm';
import OverlayAnim from '../../../assets/studio-graphique/Sama_starting_anim.webm';
import SubHypeAnim from '../../../assets/studio-graphique/SubHype_anim.webm';
import FloukyLogo from '../../../assets/studio-graphique/Flouky_logo_anim.webm';
import QuasiaLogo from '../../../assets/studio-graphique/Quasia_logo_anim.webm';
import NemoLogo from '../../../assets/studio-graphique/Nemo_logo_anim.webm';
import SupraLogo from '../../../assets/studio-graphique/Supra_logo_anim.webm';
import SxDLogo from '../../../assets/studio-graphique/SxD_logo_anim.webm';
import VoltekLogo from '../../../assets/studio-graphique/Voltek_logo_anim.webm';
import WizardsLogo from '../../../assets/studio-graphique/Wizards_logo_anim.webm';

function StudioGraphique() {
  const [selectedMedia, setSelectedMedia] = useState(null);

  const openModal = (mediaSrc) => {
    setSelectedMedia(mediaSrc);
  };

  const closeModal = () => {
    setSelectedMedia(null);
  };

  const mediaItems = [
    { src: EmoteAnim, alt: "Emote Animée DarkRach" },
    { src: EmoteNaked, alt: "Emote Naked" },
    { src: TransitionAnim, alt: "Transition Arekutsu_Sama" },
    { src: OverlayAnim, alt: "Overlay Arekutsu_Sama" },
    { src: SubHypeAnim, alt: "Animation Subway de la hype" },
    { src: FloukyLogo, alt: "Logo Animée Flouky" },
    { src: QuasiaLogo, alt: "Logo Animée Quasia" },
    { src: NemoLogo, alt: "Logo Animée Nemo" },
    { src: SupraLogo, alt: "Logo Animée Supra Event" },
    { src: SxDLogo, alt: "Logo Animée SxD" },
    { src: VoltekLogo, alt: "Logo Animée Voltek" },
    { src: WizardsLogo, alt: "Logo Animée Wizards Monkeys" }
  ];

  return (
    <section id='studio-graphique' className='section-studio'>
      <img
        src={require('../../../assets/titles/STUDIO_GRAPHIQUE_2023.webp')}
        alt="Section Studio Graphique"
        className='titlestudio'
      />
      <p>
        Bienvenue dans le Studio Graphique de Pix&Motion, <br />
        Une galerie en ligne dédiée à l’infographie et au motion design. Ici, vous pourrez découvrir mes créations originales, <br />
        allant des <strong>logos</strong> aux <strong>overlays</strong>, des <strong>Emotes</strong> aux <strong>prints</strong>, en passant par des <strong>motion designs</strong> captivants. <br />
        Si vous êtes intéressé par mes services, n’hésitez pas à me contacter via mon <a href="https://discord.gg/htVnjEqqtE">serveur Discord</a> en utilisant le système de ticket ou en utilisant le formulaire de contact ci-dessous. <br />
        Je serais ravi de collaborer avec vous pour donner vie à vos idées et créer des designs graphiques uniques et de qualité.
      </p>

      <h3>LOGO • OVERLAY • EMOTE • PRINT • MOTION DESIGN</h3>
      <div className="container-graphique">
        {mediaItems.map((media, index) => (
          typeof media.src === 'string' ? (
            media.src.endsWith('.webm') ? (
              <video
                key={index}
                src={media.src}
                onClick={() => openModal(media.src)}
                className="thumbnail"
                loop
                muted
                playsInline
                autoPlay
              >
                <track kind="descriptions" label={media.alt} />
              </video>
            ) : (
              <img
                key={index}
                src={media.src}
                alt={media.alt}
                onClick={() => openModal(media.src)}
                className="thumbnail"
              />
            )
          ) : (
            <div key={index} className="thumbnail">Unsupported media type</div>
          )
        ))}
      </div>

      {selectedMedia && (
        <div className="modalstudio" onClick={closeModal}>
          <div className="modalstudio-content" onClick={(e) => e.stopPropagation()}>
            {typeof selectedMedia === 'string' && selectedMedia.endsWith('.webm') ? (
              <video
                src={selectedMedia}
                controls
                autoPlay
                loop
                muted
                className="modal-media"
              >
                <track kind="descriptions" label="Video Description" />
              </video>
            ) : (
              <img src={selectedMedia} alt="Agrandissement" className="modal-media" />
            )}
            <button className="close-modal" onClick={closeModal}>&times;</button>
          </div>
        </div>
      )}
    </section>
  );
}

export default StudioGraphique;
