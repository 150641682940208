import React, { useState, useEffect } from 'react';
import './Twitch.scss';

// Importer les images de prévisualisation
import previewLive from '../../../assets/WALLPAPER_2023_NEW_V2.webp';
import previewChat from '../../../assets/WALLPAPER_2023_NEW_V2.webp';

function Twitch() {
  const [loadIframes, setLoadIframes] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById('accueil');
      const rect = section.getBoundingClientRect();
      if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        setLoadIframes(true);
        window.removeEventListener('scroll', handleScroll);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section id='accueil' className='section-twitch'>
        <img src={require('../../../assets/titles/accueil-2023.webp')} alt="Section Accueil" className='titletwitch' />
        <div className="twitch">
          <div className="btn-social">
            <div className="pulse"><a href="https://discord.gg/htVnjEqqtE"><img src={require('../../../assets/icons/discord.webp')} alt="Logo Discord" className="icon" /></a></div>
            <div className="pulse"><a href="https://www.facebook.com/pixetmotionTV"><img src={require('../../../assets/icons/facebook.webp')} alt="Logo Facebookk" className="icon" /></a></div>
            <div className="pulse"><a href="https://www.instagram.com/twitchpixetmotion/"><img src={require('../../../assets/icons/insta.webp')} alt="Logo Instagram" className="icon" /></a></div>
            <div className="pulse"><a href="https://www.tiktok.com/@pixetmotion"><img src={require('../../../assets/icons/tiktok.webp')} alt="Logo tiktok" className="icon" /></a></div>
            <div className="pulse"><a href="https://www.twitter.com/@pixetmotion"><img src={require('../../../assets/icons/twitter.webp')} alt="Logo Twitter" className="icon" /></a></div>
            <div className="pulse"><a href="https://www.youtube.com/@pixetmotiongaming"><img src={require('../../../assets/icons/youtube.webp')} alt="Logo Youtube" className="icon" /></a></div>
          </div>
          
          {!loadIframes ? (
            <div className="iframe-placeholder">
              <img src={previewLive} alt="Prévisualisation du Live Twitch" className="preview-image" />
              <img src={previewChat} alt="Prévisualisation du Chat Twitch" className="preview-imagechat" />
            </div>
          ) : (
            <>
              <iframe 
                className="live1"
                title='Live Pix&Motion'
                src="https://player.twitch.tv/?channel=pixetmotion&parent=lespixionautes.fr" 
                // src="https://player.twitch.tv/?channel=pixetmotion&parent=localhost" 

                frameBorder="0" 
                scrolling="no">
              </iframe>

              <iframe
                className="tchat1"
                title='Tchat Pix&Motion'
                id="chat_embed"
                frameBorder="0" 
                src="https://www.twitch.tv/embed/pixetmotion/chat?darkpopout&parent=lespixionautes.fr"
                // src="https://www.twitch.tv/embed/pixetmotion/chat?darkpopout&parent=localhost"

                >
              </iframe>
            </>
          )}
        </div>
    </section>
  );
}

export default Twitch;
