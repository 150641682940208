import React, { useState } from 'react';
import './FormCard.scss';

const Form = () => {
  const [formData, setFormData] = useState({
    email: '',
    pseudo: '',
    category: '',
    comment: '',
  });
  const [formStatus, setFormStatus] = useState(''); // Pour gérer les messages de statut du formulaire

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation basique
    if (!formData.email || !formData.pseudo || !formData.category || !formData.comment) {
      setFormStatus('Veuillez remplir tous les champs.');
      return;
    }

    // Construire le lien mailto
    const subject = encodeURIComponent(`Suggestion Card Game ${formData.pseudo}`);
    const body = encodeURIComponent(`
      Email: ${formData.email}
      Pseudo: ${formData.pseudo}
      Catégorie: ${formData.category}
      Commentaire: ${formData.comment}
    `);

    const mailtoLink = `mailto:contact@lespixionautes.fr?subject=${subject}&body=${body}`;

    // Ouvrir le client de messagerie de l'utilisateur
    window.location.href = mailtoLink;

    // Réinitialiser les données du formulaire
    setFormData({
      email: '',
      pseudo: '',
      category: '',
      comment: '',
    });

    // Message de confirmation
    setFormStatus('Merci pour votre suggestion !');
  };

  return (
    <form className="formcard" onSubmit={handleSubmit}>
      <h2>AVEZ-VOUS UNE SUGGESTION ?</h2>
      {formStatus && <p className="form-status">{formStatus}</p>}
      
      <div className="form-group">
        <label htmlFor="email-suggestion">Email</label>
        <input
          type="email"
          id="email-suggestion"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
      </div>
      
      <div className="form-group">
        <label htmlFor="pseudo-suggestion">Pseudo</label>
        <input
          type="text"
          id="pseudo-suggestion"
          name="pseudo"
          value={formData.pseudo}
          onChange={handleInputChange}
          required
        />
      </div>
      
      <div className="form-group">
        <label htmlFor="category-suggestion">Catégorie</label>
        <select
          id="category-suggestion"
          name="category"
          value={formData.category}
          onChange={handleInputChange}
          required
        >
          <option value="">Sélectionner une catégorie</option>
          <option value="category1">Nouvelle Carte</option>
          <option value="category2">Nouvelle Fonctionnalité</option>
        </select>
      </div>
      
      <div className="form-group">
        <label htmlFor="comment-suggestion">Commentaire ou message</label>
        <textarea
          id="comment-suggestion"
          name="comment"
          value={formData.comment}
          onChange={handleInputChange}
          required
        />
      </div>
      
      <button className='button-53' type="submit">Envoyer</button>
    </form>
  );
};

export default Form;
