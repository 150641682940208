import React from 'react';
import SectionYoutube from '../../../layout/LesPixionautes/Youtube/youtube';
import SectionTwitch from '../../../layout/LesPixionautes/Twitch/Twitch'
import SectionCardGame from '../../../layout/LesPixionautes/CardGame/CardGame'
import SectionStudioGraphique from '../../../layout/LesPixionautes/StudioGraphique/StudioGraphique'
import SectionStaff from '../../../layout/LesPixionautes/Staff/Staff'
import SectionPartner from '../../../layout/LesPixionautes/Partner/Partner'
import './homePage.scss';

function HomePage() {
  return (
    <div className='home'>
      <SectionYoutube />
      <SectionTwitch />
      <SectionCardGame />
      <SectionStudioGraphique />
      <SectionStaff />
      <SectionPartner />
    </div>
  );
}

export default HomePage
