import React from "react";
import { createBrowserRouter, Outlet } from "react-router-dom";

// Autres pages
import HomePage from "../pages/LesPixionautes/HomePage/HomePage";
import { ErrorPage } from "../pages/LesPixionautes/ErrorPage/ErrorPage";

// Layouts
import Header from "../components/LesPixionautes/Header/Header";
import Main from "../layout/LesPixionautes/Main/Main";
import Footer from "../layout/LesPixionautes/Footer/Footer";


const HeaderFooterLayout = () => (
  <>
    <Header />
    <Main>
      <Outlet />
    </Main>
    <Footer />
  </>
);

export const router = createBrowserRouter([
  {
    element: <HeaderFooterLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
    ],
  },
]);
