import React, { useState } from 'react';
import './Staff.scss';

// Importez vos images statiques ici
import PixetMotion from '../../../assets/staff/pixetmotion-description.webp';
import HellFire from '../../../assets/staff/hellfire-description-1-1.webp';
import Betrem from '../../../assets/staff/betrem-description-1.webp';
import Romain from '../../../assets/staff/Romain_description.webp';
import Fransceco from '../../../assets/staff/francesco-description.webp';
import Misscrx from '../../../assets/staff/misscrx-description.webp';
import Tasssimo from '../../../assets/staff/tasssimo-description-1.webp';
import PaleoLouis from '../../../assets/staff/Paleolouis_description-1.webp';

function Staff() {
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <section id='staff' className='section-staff'>
      <img
        src={require('../../../assets/titles/STAFF_2023.webp')}
        alt="Section Staff"
        className='titlestaff'
      />
      <div className="container-staff">
        {[
          { src: PixetMotion, alt: "Gérant Pix&Motion" },
          { src: HellFire, alt: "Administrateur HellFire44" },
          { src: Betrem, alt: "Développeur Betrem" },
          { src: Romain, alt: "Développeur Romain" },
          { src: Fransceco, alt: "Développeur Fransceco" },
          { src: Misscrx, alt: "Modérateur Misscrx" },
          { src: Tasssimo, alt: "Modérateur Tasssimo" },
          { src: PaleoLouis, alt: "Modérateur PaleoLouis" }

        ].map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={image.alt}
            onClick={() => openModal(image.src)}
            className="staff-thumbnail"
          />
        ))}
      </div>

      {selectedImage && (
        <div className='modalstaff' onClick={closeModal}>
          <div className='modalstaff-content' onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Modal Staff" />
            <button className="close-modal" onClick={closeModal}>&times;</button>
          </div>
        </div>
      )}
    </section>
  );
}

export default Staff;
