import React, { useState } from 'react';
import './FormFooter.scss';

const Form = () => {
  const [formData, setFormData] = useState({
    email: '',
    comment: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Préparer les données pour le lien mailto
    const subject = encodeURIComponent('Contact Form Submission');
    const body = encodeURIComponent(`
      Email: ${formData.email}
      Commentaire: ${formData.comment}
    `);

    // Construire le lien mailto
    const mailtoLink = `mailto:contact@lespixionautes.fr?subject=${subject}&body=${body}`;

    // Ouvrir le client de messagerie de l'utilisateur
    window.location.href = mailtoLink;

    // Réinitialiser les données du formulaire
    setFormData({
      email: '',
      comment: '',
    });
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <h2>CONTACTEZ NOUS</h2>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="comment">Votre message</label>
        <textarea
          id="comment"
          name="comment"
          value={formData.comment}
          onChange={handleInputChange}
          required
        />
      </div>
      <button className='button-53' type="submit">Envoyer</button>
    </form>
  );
};

export default Form;
