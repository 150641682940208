import React, { useState, useEffect } from 'react';
import './youtube.scss';

// Importer l'image de prévisualisation
import previewYoutube from '../../../assets/LOGO_PIXIONAUTES.webp';

function Youtube() {
  const [loadIframe, setLoadIframe] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector('.section-youtube');
      const rect = section.getBoundingClientRect();
      if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        setLoadIframe(true);
        window.removeEventListener('scroll', handleScroll);
      }
    };

    window.addEventListener('scroll', handleScroll);
    // Vérifier également au chargement initial
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section className='section-youtube'>
      {!loadIframe ? (
        <div className='youtube-placeholder'>
          <img src={previewYoutube} alt="Prévisualisation YouTube" className='preview-image' />
        </div>
      ) : (
      <iframe
        className='youtube-iframe'
        src="https://www.youtube-nocookie.com/embed/quPMlJ3yEqw?si=cJ5iUvoe-ma_Q1_O&autoplay=1&mute=1"
        title="YouTube video player"
        frameBorder="0"
        loading="lazy"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>

      )}
    </section>
  );
}

export default Youtube;
